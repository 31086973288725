<template>
  <modal-padrao
    ref="modal-atualizar-precos"
    :titulo="$t('modulos.servico_calibracao.modal_atualizar_preco.titulo')"
    max-width="80%"
    :ok-desabilitado="!valido"
    :titulo-ok="$t('geral.botoes.atualizar')"
    @ok="confirmacaoSalvar"
  >
    <v-form ref="form" class="row">
      <input-text
        v-model="form.percentual"
        class="col-6 col-md-6"
        :label="$t('modulos.servico_calibracao.modal_atualizar_preco.inputs.percentual_atualização')"
        type="number"
        obrigatorio
      />
      <input-select
        v-model="form.tipoArrendondamento"
        class="col-6 col-md-6"
        :label="$t('modulos.servico_calibracao.modal_atualizar_preco.inputs.arredondamento')"
        :placeholder="$t('geral.inputs.selecione')"
        :options="opcoes.arrendondamento"
        obrigatorio
      />
      <input-combobox
        v-model="selectValores"
        class="col-12 col-md-12"
        :options="opcoes.valor_atualizar"
        :label="$t('modulos.servico_calibracao.modal_atualizar_preco.inputs.valores_atualizar')"
        retornar-objeto
        obrigatorio
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import _ from 'lodash';
export default {
  data() {
    return {
      valido: null,
      percentual: null,
      tipoArrendondamento: null,
      selectValores: [],
      form:{},
      formReferencia: {},
      opcoes: {
        arrendondamento: helpers.ArredondamentoEnum,
        valor_atualizar: helpers.ValorAtulizarEnum,
      },
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
    },
    selectValores: {
      deep:true,
      handler() {
        this.valoresPercentuais()
      }
    }
  },
  mounted() {
    this.iniciarForm();
  },
  methods: {
    iniciarForm: function () {
      this.form = {
       ...this.form,
        tipoArrendondamento: null,
      }
      this.selectValores = []
    },
    abrirModal: function (form) {
      this.iniciarForm();
      if (form) {
        this.formReferencia = form;
        this.form = _.cloneDeep(form);
      }
      this.$refs['modal-atualizar-precos'].abrirModal(this.form );
      if (this.$refs.form) this.valido = this.$refs.form.resetValidation();
    },
    salvar: function () {
      if (!this.$refs.form.validate()) return;
      this.$emit('salvar-novo', this.form);
      this.$refs['modal-atualizar-precos'].fecharModal();
    },
    confirmacaoSalvar: function () {
      this.confirmarSalvar().then(() => {
          this.salvar();
        });
    },
    valoresPercentuais: function () {
      this.selectValores.filter((i) => {
        switch (i.value) {
          case 1:
            this.form.percentualValorServico = this.form.percentual;
            break;
          case 2:
            this.form.percentualValorHora = this.form.percentual;
            break;
          case 3:
            this.form.percentualValorPonto = this.form.percentual;
            break;
        }
      });
    }
  },
};
</script>
