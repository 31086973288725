<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.nome')"
      :placeholder="$t('modulos.servico_calibracao.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.codigo"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.codigo')"
      :placeholder="$t('modulos.servico_calibracao.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.localExecucao"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.local_execucao')"
      :placeholder="$t('modulos.servico_calibracao.filtro.local_execucao')"
      em-filtro
    />
    <input-select
      v-model="filtro.tipoCalibracaoId"
      :options="opcoes.tiposCalibracao"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.tipo_calibracao')"
      :placeholder="$t('geral.inputs.selecione')"
      em-filtro
      retornar-objeto
    />
    <input-text
      v-model="filtro.tipoInstrumento"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.tipo_instrumento')"
      :placeholder="$t('modulos.servico_calibracao.filtro.tipo_instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.modeloInstrumento"
      class="col-6"
      :label="$t('modulos.servico_calibracao.filtro.modelo_instrumento')"
      :placeholder="$t('modulos.servico_calibracao.filtro.modelo_instrumento')"
      em-filtro
    />
  </div>
</template>
<script>
import { DropdownModel } from '@common/models/geral/DropdownModel';
import TipoCalibracaoService from '@common/services/cadastros/TipoCalibracaoService';

export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        tiposCalibracao: [],
      }
    };
  },
  mounted() {
    this.buscarTiposCalibracao();
  },
  methods: {
    buscarTiposCalibracao: function () {
      TipoCalibracaoService.listar().then((res) => {
        this.opcoes.tiposCalibracao = new DropdownModel(res.data);
      });
    },
  }
};
</script>

