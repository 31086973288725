<template>
  <modal-padrao
    ref="modal-duplicar-servico"
    :titulo="$t('modulos.servico_calibracao.modal_duplicar.titulo')"
    max-width="80%"
    :ok-desabilitado="!valido"
    :titulo-ok="$t('geral.botoes.duplicar')"
    @ok="confirmacaoSalvar"
  >
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.codigo"
        class="col-12 col-md-12"
        :label="$t('modulos.servico_calibracao.modal_duplicar.input.codigo')"
        :max="20"
        obrigatorio
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import _ from "lodash";
export default {
  data() {
    return {
      valido: null,
      form: {},
      formReferencia: {},
    };
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    }
  },
  mounted() {
    this.iniciarForm();
  },
  methods: {
    iniciarForm: function () {
      this.form = {
        ordem: 0,
        tipoRequisito: null,
        descricao: null,
      };
    },
    abrirModal: function (form) {
      this.iniciarForm();
      if (form) {
        this.valido = true;
        this.formReferencia = form;
        this.form = _.cloneDeep(form);
      }
      this.$refs["modal-duplicar-servico"].abrirModal();
      if (this.$refs.form) this.valido = this.$refs.form.resetValidation();
    },
    salvar: function () {
      if (!this.$refs.form.validate()) return;
      this.$emit("salvar-novo", this.form);

      this.$refs["modal-duplicar-servico"].fecharModal();
    },
    confirmacaoSalvar: function () {
      this.salvar();
    },
  },
};
</script>
