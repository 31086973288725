export class AtualizarPrecoServicoModel {
  constructor({
    servicos = [],
    tipoArrendondamento,
    percentualValorServico,
    percentualValorHora,
    percentualValorPonto
  }) {
    this.servicos = servicos;
    this.tipoArrendondamento = tipoArrendondamento;
    this.percentualValorServico = percentualValorServico;
    this.percentualValorHora = percentualValorHora;
    this.percentualValorPonto = percentualValorPonto;
  }
}
